import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, CUSTOM_ELEMENTS_SCHEMA, effect, ElementRef, Injector, ViewChild } from '@angular/core';
import { AppCommonModule } from '@app/shared/common/app-common.module';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CandidateListComponent } from "./candidate-list.component";
import { CandidateTechPackComponent } from "./candidate-tech-pack.component";
import { CandidateService } from './candidate.service';
import { CandidateChecklistComponent } from './candidate-checklist.component';
//import { CandidateDetailComponent } from './candidate-detail.component';
import { CandidateDetailTabsComponent } from './candidate-detail-tabs.component';
//import { SlDrawer } from '@shoelace-style/shoelace';

@Component({
  selector: 'dcar-candidate',
  standalone: true,
  imports: [CommonModule, AppCommonModule, CandidateListComponent, CandidateTechPackComponent, CandidateChecklistComponent, 
    //CandidateDetailComponent, 
    CandidateDetailTabsComponent],
  templateUrl: './candidate.component.html',
  styleUrl: './candidate.component.css',
  animations: [appModuleAnimation()],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CandidateComponent extends AppComponentBase implements AfterViewInit {
  @ViewChild('detailTab') detailTab!: ElementRef;
  @ViewChild('candidateDetailTabsDrawer') candidateDetailTabsDrawer: ElementRef;
  @ViewChild('candidateDetailTabsComponent') candidateDetailTabsComponent:CandidateDetailTabsComponent;
//  @ViewChild('candidateDetailComponent') candidateDetailComponent:CandidateDetailComponent;

  constructor(
    injector: Injector,
    public _candidateService: CandidateService
  ) {
    super(injector);

    

    effect(() => {
      const open = this.signalService.open_candidate_detail_drawer();
      if (open) {
        this.candidateDetailTabsDrawer.nativeElement.show();
      }
    });
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.candidateDetailTabsDrawer.nativeElement.addEventListener('sl-after-hide', () => {
      this.signalService.open_candidate_detail_drawer.set(false);
    });
  }



  focusDetailTab(): void {
    if (this.detailTab) {
      this.detailTab.nativeElement.focus();
    }
  }

  closeDrawer() {
    this.candidateDetailTabsDrawer.nativeElement.hide();
    this.signalService.open_candidate_detail_drawer.set(false);
  }

  toggleDisabled() {
    this.candidateDetailTabsComponent.toggleDiabled();
  }

  submitForm() {
    this.candidateDetailTabsComponent.submitForm();
  }

}
