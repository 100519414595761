<div [@routerTransition]>
    <div [class]="containerClass">
        <sl-tab-group>
            <sl-tab #listTab slot="nav" panel="list-panel">List</sl-tab>
            <sl-tab #techPackTab slot="nav" panel="tech-pack-panel">Tech Packs</sl-tab>
            <!-- <sl-tab #detailTab slot="nav" panel="detail-panel">Detail{{_candidateService.selectedCandidateName}}</sl-tab> -->
            <sl-tab #checkListTab slot="nav" panel="checklist-panel">Checklist</sl-tab>
          
            <sl-tab-panel name="list-panel">
                <dcar-candidate-list></dcar-candidate-list>
            </sl-tab-panel>
            <sl-tab-panel name="tech-pack-panel">
                <dcar-candidate-tech-pack></dcar-candidate-tech-pack>
            </sl-tab-panel>
            <!-- <sl-tab-panel name="detail-panel"> -->
                <!-- -> {{_candidateService.candidateDetailForm.value | json}} -->
                <!-- <dcar-candidate-detail></dcar-candidate-detail> -->
            <!-- </sl-tab-panel> -->
            <sl-tab-panel name="checklist-panel">
                <dcar-candidate-checklist></dcar-candidate-checklist>
            </sl-tab-panel>
          </sl-tab-group>
    </div>

    <sl-drawer #candidateDetailTabsDrawer id="candidateDetailTabsDrawer" class="drawer-overview" style="--size: 85vw;" no-header>
        <header class="drawer_title">
            {{_candidateService.selectedCandidateName}}
        </header>

        <dcar-candidate-detail-tabs #candidateDetailTabsComponent></dcar-candidate-detail-tabs>
        
        <sl-button slot="footer" variant="success" type="button" (click)="submitForm()" class="mr-2">Submit</sl-button>
        <sl-button slot="footer" variant="danger" type="button" class="mr-2" (click)="toggleDisabled()">Toggle Disabled</sl-button>
        <sl-button slot="footer" variant="primary" class="mr-2" (click)="closeDrawer()">Close</sl-button>
      </sl-drawer>

</div>
