<form #candidateChecklist ngNoForm [formGroup]="_candidateService.candidateChecklistForm" (ngSubmit)="submitForm()">
    <div class="grid">
        <div class="col-2">
            <label for="delivery-type">Select Delivery Type</label>
        </div>
        <div class="col-4">
            <div class="form-group">
                <sl-select id="delivery_type" formControlName="delivery_type">
                    <sl-option value="Email">Email</sl-option>
                    <sl-option value="Regular Mail-USPS">Regular Mail-USPS</sl-option>
                    <sl-option value="2 Day">2 Day</sl-option>
                    <sl-option value="3 Day">3 Day</sl-option>
                    <sl-option value="Standard Overnight">Standard Overnight</sl-option>
                    <sl-option value="Priority Overnight">Priority Overnight</sl-option>
                </sl-select>
            </div>
        </div>
        <div class="col-6"></div>

        <div class="col-2">
            <label for="date-techpack-sent">Date Techpack Sent</label>
        </div>
        <div class="col-4">
            <div class="form-group">
                <sl-input id="date_techpack_sent" formControlName="date_techpack_sent"
                    value="{{ _candidateService.candidateChecklistForm.get('date_techpack_sent').value | date: 'yyyy-MM-dd' }}"
                    type="date"></sl-input>
            </div>
        </div>
        <div class="col-6"></div>

        <div class="col-2">
            <label for="date-of-birth">Date of Birth</label>
        </div>
        <div class="col-4">
            <div class="form-group">
                <div class="dx-field">
                    <div class="dx-field-label">Date of Birth</div>
                    <div class="dx-field-value">
                        <dx-date-box type="date" id="date_of_birth" formControlName="date_of_birth" [value]="_candidateService.candidateChecklistForm.get('date_of_birth').value | date: 'yyyy-MM-dd'" [inputAttr]="{ 'aria-label': 'Date of Birth' }">
                        </dx-date-box>
                    </div>
                </div>
                <!-- <sl-input id="date_of_birth" formControlName="date_of_birth"
                    value="{{ _candidateService.candidateChecklistForm.get('date_of_birth').value | date: 'yyyy-MM-dd' }}"
                    type="date"></sl-input> -->
            </div>
        </div>
        <div class="col-6"></div>

        <div class="col-2">
            <div class="dx-field-label">Social Security Number</div>
        </div>
        <div class="col-4">
            <div class="form-group">
                <div class="dx-field-value">
                    <dx-text-box id="social_security_number" formControlName="social_security_number"
                        value="{{ _candidateService.candidateChecklistForm.get('social_security_number').value }}"
                        [inputAttr]="{ 'aria-label': 'Social Security Number' }"></dx-text-box>
                </div>
                <!-- <sl-input id="social_security_number" formControlName="social_security_number"
                    value="{{ _candidateService.candidateChecklistForm.get('social_security_number').value }}"
                    type="text"></sl-input> -->
            </div>
        </div>
        <div class="col-6"></div>

        <div class="col-2">
            <label for="citizen-status">Citizen Status</label>
        </div>
        <div class="col-4">
            <div class="form-group">
                <sl-select id="citizen_status" formControlName="citizen_status">
                    <sl-option value="Citizen or National">Citizen or National</sl-option>
                    <sl-option value="Permanent Resident Alien">Permanent Resident Alien</sl-option>
                    <sl-option value="Temporary Alien">Temporary Alien</sl-option>
                </sl-select>
            </div>
        </div>
        <div class="col-6"></div>

        <div class="col-12 n-bb-grey-1"></div>

        <div class="col-12">
            <h5>Documents</h5>
        </div>

        <div class="col-2">
            <label for="signed-application-received">Signed Application Received</label>
        </div>
        <div class="col-4">
            <div class="form-group">
                fv: {{_candidateService.candidateChecklistForm.get('signed_application_received').value}}
                <sl-checkbox #signed_application_received id="signed_application_received" (click)="changeVal($event)"
                    formControlName="signed_application_received"></sl-checkbox>
                <sl-input
                    [attr.disabled]="_candidateService.candidateChecklistForm.get('signed_application_received').value == true? true: null"
                    id="signed_application_received_date" formControlName="signed_application_received_date"
                    value="{{ _candidateService.candidateChecklistForm.get('signed_application_received_date').value | date: 'yyyy-MM-dd' }}"
                    type="date"></sl-input>
            </div>
        </div>
        <div class="col-6"></div>

        <div class="col-2">
            <label for="code-of-ethics-received">Code of Ethics Received</label>
        </div>
        <div class="col-4">
            <div class="form-group">
                <sl-checkbox #code_of_ethics_received id="code_of_ethics_received"
                    formControlName="code_of_ethics_received"></sl-checkbox>
                <sl-input
                    [attr.disabled]="_candidateService.candidateChecklistForm.get('code_of_ethics_received').value == true? true: null"
                    id="code_of_ethics_received_date" formControlName="code_of_ethics_received_date"
                    value="{{ _candidateService.candidateChecklistForm.get('code_of_ethics_received_date').value | date: 'yyyy-MM-dd' }}"
                    type="date"></sl-input>
            </div>
        </div>
        <div class="col-6"></div>




        <!-- <div class="col-4">
        <div class="form-group">
            <sl-select class="label-on-left mb-1" label="Select Delivery Type" id="delivery_type" formControlName="delivery_type">
                <sl-option value="Email">Email</sl-option>
                <sl-option value="Regular Mail-USPS">Regular Mail-USPS</sl-option>
                <sl-option value="2 Day">2 Day</sl-option>
                <sl-option value="3 Day">3 Day</sl-option>
                <sl-option value="Standard Overnight">Standard Overnight</sl-option>
                <sl-option value="Priority Overnight">Priority Overnight</sl-option>
            </sl-select>
        <div class="form-group">
            <sl-input class="label-on-left mb-1" label="Date Techpack Sent" [attr.disabled]="controlsDisabled == true? true: null" id="date_techpack_sent" formControlName="date_techpack_sent"
                value="{{ _candidateService.candidateChecklistForm.get('date_techpack_sent').value | date: 'yyyy-MM-dd' }}" type="date"></sl-input>
        </div>
        <div class="form-group">
            <sl-input class="label-on-left mb-1" label="Date of Birth" [attr.disabled]="controlsDisabled == true? true: null" id="date_of_birth" formControlName="date_of_birth"
                value="{{ _candidateService.candidateChecklistForm.get('date_of_birth').value | date: 'yyyy-MM-dd' }}" type="date"></sl-input>
        </div>
        <div class="form-group">
            <sl-input class="label-on-left mb-1" label="Social Security Number" [attr.disabled]="controlsDisabled == true? true: null" id="social_security_number" formControlName="social_security_number"
                value="{{ _candidateService.candidateChecklistForm.get('social_security_number').value }}" type="text"></sl-input>
        </div>
        <div class="form-group">
            <sl-select class="label-on-left mb-1" label="Citizen Status" id="citizen_status" formControlName="citizen_status">
                <sl-option value="Citizen or National">Citizen or National</sl-option>
                <sl-option value="Permanent Resident Alien">Permanent Resident Alien</sl-option>
                <sl-option value="Temporary Alien">Temporary Alien</sl-option>
            </sl-select>
        </div>
    </div>
    
    <div class="col-8"></div>

    <div class="col-12 n-bb-grey-1"></div>

    <div class="col-12">
        <h5>Documents</h5>
    </div>

    <div class="col-12">
        <div class="form-group">
            <sl-checkbox  [attr.disabled]="controlsDisabled == true? true: null" id="signed_application_received" formControlName="signed_application_received"></sl-checkbox>
            <sl-input class="label-on-left mb-1" label="Signed Application Received" [attr.disabled]="controlsDisabled == true? true: null" id="signed_application_received_date" formControlName="signed_application_received_date"
                value="{{ _candidateService.candidateChecklistForm.get('signed_application_received_date').value | date: 'yyyy-MM-dd' }}" type="date"></sl-input>
        </div>
    </div>
    <div class="col-12">
        <div class="form-group">
            <sl-checkbox [attr.disabled]="controlsDisabled == true? true: null" id="code_of_ethics_received" formControlName="code_of_ethics_received"></sl-checkbox>
            <sl-input class="label-on-left mb-1" label="Code of Ethics Received" [attr.disabled]="controlsDisabled == true? true: null" id="code_of_ethics_received_date" formControlName="code_of_ethics_received_date"
                value="{{ _candidateService.candidateChecklistForm.get('code_of_ethics_received_date').value | date: 'yyyy-MM-dd' }}" type="date"></sl-input>
        </div>
    </div> -->
    </div>
</form>