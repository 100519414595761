import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, Injector, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CandidateService } from './candidate.service';
import { DxDateBoxModule, DxTextBoxModule } from 'devextreme-angular';

@Component({
  selector: 'dcar-candidate-checklist',
  standalone: true,
  imports: [CommonModule,FormsModule, ReactiveFormsModule, DxTextBoxModule, DxDateBoxModule],
  templateUrl: './candidate-checklist.component.html',
  styleUrl: './candidate-checklist.component.css',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CandidateChecklistComponent extends AppComponentBase {
  @ViewChild('candidateChecklist') candidateChecklist
  
  controlsDisabled = true;
  
  constructor(
    injector: Injector,
    public _candidateService: CandidateService
  ) {
    super(injector);
  }

  submitForm() {
    this.candidateChecklist.nativeElement.submit();
  }

  changeVal(e) {
    console.log('e',e);
  }

}
