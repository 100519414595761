import { Component } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
  selector: 'technical-specialists-menu',
  template: `
      <span class="mr-1" *ngIf="'Pages' | permission">
          <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='technical-specialists-dashboard' || headerService.display_sub_page===''}" (click)="headerService.displaySubPage('technical-specialists-dashboard')" type="button">
              <fa-icon [icon]="icon.farList" class="mr-1"></fa-icon>
              {{ 'Dashboard' | localize }}
          </button>
      </span>
      <span class="mr-1" *ngIf="'Pages' | permission">
          <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='technical-specialists-list'}" (click)="headerService.displaySubPage('technical-specialists-list')" type="button">
              <fa-icon [icon]="icon.farList" class="mr-1"></fa-icon>
              {{ 'List' | localize }}
          </button>
      </span>
      <span class="mr-1" *ngIf="'Pages' | permission">
          <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='technical-specialists-drug-tests'}" (click)="headerService.displaySubPage('technical-specialists-drug-tests')" type="button">
              <fa-icon [icon]="icon.farList" class="mr-1"></fa-icon>
              {{ 'DrugTests' | localize }}
          </button>
      </span>
      <span class="mr-1" *ngIf="'Pages' | permission">
          <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='technical-specialists-eye-exams'}" (click)="headerService.displaySubPage('technical-specialists-eye-exams')" type="button">
              <fa-icon [icon]="icon.farList" class="mr-1"></fa-icon>
              {{ 'EyeExams' | localize }}
          </button>
      </span>
      <span class="mr-1" *ngIf="'Pages' | permission">
          <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='technical-specialists-stamp-control'}" (click)="headerService.displaySubPage('technical-specialists-stamp-control')" type="button">
              <fa-icon [icon]="icon.farList" class="mr-1"></fa-icon>
              {{ 'StampControl' | localize }}
          </button>
      </span>
      <span class="mr-1" *ngIf="'Pages' | permission">
          <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='technical-specialists-tech-packs'}" (click)="headerService.displaySubPage('technical-specialists-tech-packs')" type="button">
              <fa-icon [icon]="icon.farList" class="mr-1"></fa-icon>
              {{ 'TechPacks' | localize }}
          </button>
      </span>
      <span class="mr-1" *ngIf="'Pages' | permission">
        <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='technical-specialists-work-assignments'}" (click)="headerService.displaySubPage('technical-specialists-work-assignments')" type="button">
            <fa-icon [icon]="icon.farList" class="mr-1"></fa-icon>
            {{ 'WorkAssignments' | localize }}
        </button>
    </span>
    <span class="mr-1" *ngIf="'Pages' | permission">
        <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='technical-specialists-compliance'}" (click)="headerService.displaySubPage('technical-specialists-compliance')" type="button">
            <fa-icon [icon]="icon.farList" class="mr-1"></fa-icon>
            {{ 'Compliance' | localize }}
        </button>
    </span>
    <span class="mr-1" *ngIf="'Pages' | permission">
        <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='technical-specialists-leave'}" (click)="headerService.displaySubPage('technical-specialists-leave')" type="button">
            <fa-icon [icon]="icon.farList" class="mr-1"></fa-icon>
            {{ 'Leave' | localize }}
        </button>
    </span>
    <span class="mr-1" *ngIf="'Pages' | permission">
        <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='technical-specialists-exams'}" (click)="headerService.displaySubPage('technical-specialists-exams')" type="button">
            <fa-icon [icon]="icon.farList" class="mr-1"></fa-icon>
            {{ 'Exams' | localize }}
        </button>
    </span>
  `,
  styles: ``
})
export class TechnicalSpecialistsMenuComponent extends AppComponentBase {

}
